@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  background-color: #17181f;
  color: #ddd;
  
}
*{
  box-sizing: border-box;
}
.App{
  max-width: 450px;
  margin: 20px auto;
  padding-top: 50px;
}
.title{
  font-family: 'Ubuntu', sans-serif;
  font-size: 45px;
  display: block;
  text-align: center;
  padding: 5px;
  letter-spacing: 3px;
  font-weight: bold;
}

form {
  border: 3px solid #30313d;
  display: flex;
  padding: 5px ;
  border-radius: 10px;
  height: 55px;
}

form input[type='text'] {
  background:none;
  border: 0;
  border-radius: 6px;
  padding: 0 8px;
  display: block;
  width: 100%;
  color: #fff;
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  letter-spacing: 0.03rem;
  
}

form button {
  background-color: #ffbc00;
  color: #333;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px;
  width: 40px;
  text-align: center;
  font-size: 29px;
}
form button:hover{
  font-size: 35px;
}




.task{
  background-color: #20212d;
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 15px;
  display:flex;
  transition: opacity .3s linear;
  align-items: center;
  height: 35px;
}

.task svg{
  height: 25px;
  margin-right:16px;
  fill: #ddd;
  margin-left: 5px
}
div.checkbox{
  display: flex;
  align-items: center;
}
.taskName{
  flex-grow: 1;
  font-size: 22px;
  color: #ddd;
  
}
.task span{
  position: relative;
}

.task.done{
  text-decoration: line-through;
  opacity: 0.3;

}

h1, h2{
  text-align: center;
  padding-bottom: 5px;
}

button.trash{
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
  
}

button.trash svg{
  fill: #ddd;
  height: 22px;
  
}
button.trash svg:hover{
  height: 25px;
}

.footer{
  padding-top: 150px;
  visibility: visible;
  transition: 2s;
}
.hidden{
  visibility: hidden;
}
footer h4 {
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8rem;
  color: grey;
  letter-spacing: 2px;
  font-weight: lighter;
  text-align: center;
  padding-top: 75px;
}
.redTitle {
  color:rgb(165, 5, 5);
  text-decoration: none;
}


.icons{
  display: flex;
  align-items: center;
  justify-content: center;
  
  padding: 5px 0;
  
}
.icons a svg{
  height: 30px;
  fill: #ddd;
  transition: 0.5s;
  cursor: pointer;
  margin: 0 10px;
}

.icons a:hover svg {
  transform: translateY(-3px) scale(1.2);
  fill:#ffbc00;
}




@media screen and (max-width: 600px){
  .App{
    max-width: 300px;
    margin: 20px auto;
    padding-top: 30px;
  }
  .App{
    max-width: 400px;
    margin: 20px auto;
  }
  .title{
    font-size: 35px;
    
    
  }
  form {

    border-radius: 6px;
    height: 40px;
  }
  form input[type='text'] {
    
    
    font-size: 18px;
    letter-spacing: 0;
  }
  form button {
    
    width: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  form button:hover{
    font-size: 21px;
  }
  .taskName{
    flex-grow: 1;
    font-size: 17px;
    font-weight: 600;
    
  }
  h4 {
    
    font-size: 0.6rem;

  }
  footer{
    padding-top: 100px;
  }
  footer h4 {
    
    font-size: 0.6rem;
    color: grey;
    letter-spacing: 1px;
    font-weight: lighter;
    text-align: center;
    padding-top: 45px;
  }


}